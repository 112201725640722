export const REG_PAGE_TIMEOUT_IN_MS = 300000; // 5 minutes
export const AUTH_PAGE_TIMEOUT_IN_MS = 300000; // 5 minutes
export const AUTO_LOGOUT_TIME_IN_MS = 10000; // 10 seconds
export const WEB_AUTHN_TIMEOUT_IN_S = 45;
export const REQUIRED_PIN_LENGTH = 6;
export const REQUIRED_TAC_LENGTH = 96;
export const VALID_SUBSCRIBER_IDS = [
  'faast-na',
  'faast-eu',
  'faast-apac',
  'tcp_na',
  'tcp_eu',
  'tcp_fe',
];

export const BACKEND_TO_FRONTEND_AUTHENTICATION_ERROR_MAPPING: Record<string, string> = {
  'not_authorized': 'USER109',
  'invalid_session': 'USER110',
  'unexpected_error': 'ISE100',
};

export const AUTHENTICATION_ERROR_PAGE_CODE_MAPPING:Record<string, Record<string, string>> = {
  'not_authorized': {
    'title': 'authentication_error_page_not_authorized_title',
    'description': 'authentication_error_page_not_authorized_description',
  },
  'invalid_session': {
    'title': 'authentication_error_page_invalid_session_title',
    'description': 'authentication_error_page_invalid_session_description',
  },
  'unexpected_error': {
    'title': 'internal_server_error_title',
    'description': 'internal_server_error_description',
  },
};

export const BACKEND_TO_FRONTEND_REGISTRATION_ERROR_MAPPING: Record<string, string>= {
  'invalid_session': 'USER111',
  'ineligible_for_registration': 'USER112',
  'pin_registration_restricted': 'USER113',
  'tac_generation_restricted': 'USER114',
  'device_unsupported': 'USER115',
};

export const REGISTRATION_ERROR_PAGE_CODE_MAPPING:Record<string, Record<string, string>> = {
  'invalid_session': {
    'title': 'registration_error_invalid_session_title',
    'description': 'registration_error_invalid_session_description',
  },
  'ineligible_for_registration': {
    'title': 'registration_error_ineligible_title',
    'description': 'registration_error_ineligible_description',
  },
  'pin_registration_restricted': {
    'title': 'pin_registration_restricted_title',
    'description': 'pin_registration_restricted_description',
  },
  'tac_generation_restricted': {
    'title': 'tac_generation_restricted_title',
    'description': 'tac_generation_restricted_description',
  },
};

export enum UserErrorsEnum {
  MAX_USAGE_ERROR = 'max_usage_error',
  EXPIRED_ERROR = 'expired_error',
  REG_CODE_MAX_ATTEMPTS_EXCEEDED_ERROR = 'reg_code_max_attempts_exceeded_error',
  DEVICE_UNSUPPORTED_ERROR = 'device_unsupported_error',
}

/**
 * Custom error to represent user specific errors that needs to be shown in the UI.
 */
export class UserError extends Error {
  /**
  * @constructor
  * @param {String} name - Name of the subset error.
  */
  constructor(name: string) {
    super();
    this.name = name;
  }
}

export enum ClientMetricsCodesEnum {
    USER_TAC_ERROR = 'USER100',
    USER_REGCODE_ERROR = 'USER101',
    FIDO2_AUTHN_INVALID_CRED = 'USER102',
    FIDO2_AUTHN_INVALID_CHALLENGE = 'USER103',
    FIDO2_REGISTRATION_INVALID_CRED = 'USER104',
    FIDO2_REGISTRATION_INVALID_CHALLENGE = 'USER105',
    REGISTRATION_ERROR_PAGE = 'USER106',
    NOT_FOUND = 'USER107',
    AUTHENTICATION_ERROR_PAGE = 'USER108',
    FIDO2_REGISTRATION_INVALID_AUTHENTICATOR = 'USER115',
    CSRF_SERVER_ERROR = 'ISE100',
    PIN_REGISTRATION_SERVER_ERROR = 'ISE101',
    TAC_GENERATION_SERVER_ERROR = 'ISE102',
    PIN_JWKS_SERVER_ERROR = 'ISE103',
    USER_DETAILS_SERVER_ERROR = 'ISE104',
    REGCODE_VALIDATION_SERVER_ERROR = 'ISE105',
    FIDO2_AUTHN_SERVER_ERROR = 'ISE106',
    FIDO2_REGISTRATION_SERVER_ERROR = 'ISE107',
}

export enum ClientMetricsAttributesEnum {
    CLIENT_ERROR = 'CLIENT_ERROR',
}

import React from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {ErrorAlertProps} from '../../utils/ErrorTypes';


interface Props extends ErrorAlertProps {
  headerId?: string,
  dataId?: string,
  messageId?: string,
}

const RegistrationFailure: React.FC<Props> = ({headerId, dataId, messageId, errorCode}) => {
  const bundle = useAuthgardBundle();

  const headerName = headerId ? headerId : 'registration_failure_title';
  const messageName = messageId ? messageId : 'registration_failure_description';
  const header = errorCode ?
    `${errorCode}: ${bundle.getMessage(headerName)}` :
    bundle.getMessage(headerName);

  return (
    <Alert
      statusIconAriaLabel="Error"
      type="error"
      data-testid={dataId ? dataId : 'registration-failure'}
      header={header}
    >
      {bundle.getMessage(messageName)}
    </Alert>
  );
};

export default RegistrationFailure;
